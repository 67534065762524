import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class DocumentService {
  private readonly apiPrefix = '/client/documents';

  constructor(
    private api: ApiService
  ) {
  }

  getMyDocumentList(queryString = '') {
    return this.api.get(`${this.apiPrefix}/my-list${queryString}`);
  }

  getPublicDocuments(queryString = '') {
    return this.api.get(`${this.apiPrefix}/public-list${queryString}`);
  }

  getLevelBasedDocuments(queryString = '') {
    return this.api.get(`${this.apiPrefix}/level-based-list${queryString}`);
  }

  createDocumentUploadRequest(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  saveDocument(requestId: string) {
    return this.api.post(`${this.apiPrefix}/${requestId}`, {});
  }

  updateDocument(documentId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${documentId}`, data);
  }

  deleteDocument(documentId: number) {
    return this.api.delete(`${this.apiPrefix}/${documentId}`);
  }

}
